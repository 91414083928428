<template>
    <div v-if="display" :id="'streaming-container-' + sid" class="intrinsic-container" @mouseenter="mouseEnterContainer" @mouseleave="mouseLeaveContainer">
        <div class="main-panel-body player-container-expanded">
            <div class="player-container-4x3 player-container-expanded">
                <div class="streaming-channel-player-container"><HLSPlayer ref="hlsPlayer" :live="!chosenVideo" @channel-switch="onChannelSwitch" :channel="sid" :thumbnail="thumbnail" :channelname="sTitle" :autoplay="true" :autoloop="true" @resolution="onRequestResolution" @media-error="onMediaError" @ended="onPlayerStall" @can-play="onPlayerReady" :errmsg="getErrorMsg(!live && !playerReady && !chosenVideo, error, firstLoaded)"></HLSPlayer></div>
            </div>
        </div>
        <div @mousedown="movePlayer" class="streaming-modal-controls" :class="{'hidden': !showConfigControls && live, 'player-min': minPlayer, 'player-max-streaming-modal': !minPlayer}">
            <div class="player-controls-left">
                <button @mousedown="resizePlayer" type="button" :title="$t('Resize window')" class="player-btn player-play-btn btn-resize"><i class="fas fa-expand-alt"></i></button>
                <label class="player-title">{{sTitle}}</label>
            </div>
            <div class="player-controls-right">
                <button @click="closePlayer" @mousedown="closePlayer" type="button" :title="$t('Close window')" class="player-btn player-play-btn"><i class="fas fa-times"></i></button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, nextTick } from "vue";
import { Request } from "@asanrom/request-browser";
import { Timeouts } from "@/utils/timeout";
import { getUniqueStringId } from "@/utils/unique-id";

import { RoomController } from '@/control/room';
import { AuthController } from "@/control/auth";

import { ApiStreaming } from "@/api/api-group-streaming";

import HLSPlayer from "@/components/player/HLSPlayer.vue";

export default defineComponent({
    components: {
        HLSPlayer,
    },
    name: "RoomSectionStreamingModal",
    props: {
        display: Boolean,
        room: String,
        sid: String,
        sTitle: String,
    },
    setup() {
        return {
            loadRequestId: getUniqueStringId(),
            loadVideoRequestId: getUniqueStringId(),
            loadResolutionRequestId: getUniqueStringId(),
            loadVideoResolutionRequestId: getUniqueStringId(),
            loadSpectatorsRequestId: getUniqueStringId(),
            loadStreamRecordingsListRequestId: getUniqueStringId(),
        };
    },
    data: function () {
        return {
            error: "",
            loading: true,

            live: false,
            started: 0,
            title: "",
            thumbnail: "",
            resolutions: [],
            offlineVideoId: "",
            offlineVideoTitle: "",

            playerReady: false,

            canAdmin: false,
            isAdmin: AuthController.isAdmin(),

            interval: null,

            more: true,
            loadingVideos: false,
            videos: [],

            nextUrl: "",

            chosenVideo: false,
            vid: "",
            chosenVideoTitle: "",

            firstLoaded: false,

            spectators: 1,
            loadingSpectators: false,
            lastTimeSpectators: 0,

            isDragReady: false,
            el: document.getElementById("draggableContainer"),
            mainContainer: document.getElementsByClassName("main-layout")[0],
            dragoffset: {
                x: 0,
                y: 0
            },

            showConfigControls: true,

            startSizeX: 0,
            startSizeY: 0,
            startSizeWidth: 0,
            startSizeHeight: 0,

            minPlayer: false
        };
    },
    methods: {
        loadSpectators: function () {
            if (!this.sid) {
                return;
            }
            if (this.loadingSpectators) {
                return;
            }

            this.loadingSpectators = true;

            const sid = this.sid;

            Timeouts.Abort(this.loadSpectatorsRequestId);
            Request.Abort(this.loadSpectatorsRequestId);

            Request.Pending(this.loadSpectatorsRequestId, ApiStreaming.GetRoomRoomidSectionSectionidStreamingStatsLive(this.room, this.sid, {}))
                .onSuccess((response) => {
                    this.loadingSpectators = false;
                    this.spectators = Math.max(1, response.spectators);
                    this.lastTimeSpectators = Date.now();
                })
                .onRequestError(() => {
                    this.loadingSpectators = false;

                    if (this.sid === sid) {
                        this.lastTimeSpectators = Date.now();
                    }
                })
                .onUnexpectedError((err) => {
                    console.error(err);
                    this.loadingSpectators = false;

                    if (this.sid === sid) {
                        this.lastTimeSpectators = Date.now();
                    }
                });        
        },

        loadInit: function () {
            this.firstLoaded = false;
            Request.Abort(this.loadSpectatorsRequestId);
            this.spectators = 1;
            this.lastTimeSpectators = 0;
            this.load();
        },

        onChannelSwitch: function () {
            this.firstLoaded = false;
            Request.Abort(this.loadSpectatorsRequestId);
            this.spectators = 1;
            this.lastTimeSpectators = 0;
            this.load();
        },

        load: function () {
            Timeouts.Abort(this.loadRequestId);
            Request.Abort(this.loadRequestId);

            this.error = "";

            if (!this.sid) {
                this.loading = false;
                this.firstLoaded = true;
                return;
            }

            Request.Pending(this.loadRequestId, ApiStreaming.GetRoomRoomidSectionSectionidStreaming(this.room, this.sid, {}))
                .onSuccess((response) => {
                    this.title = response.title;
                    this.thumbnail = response.thumbnail;
                    this.live = response.live;
                    this.started = response.started;
                    this.resolutions = response.resolutions || [];
                    this.offlineVideoId = response.offlineVideoId;
                    this.offlineVideoTitle = response.offlineVideoTitle;

                    this.loading = false;
                    this.firstLoaded = true;

                    if (this.live) {
                        this.closeVideo();
                    } else if (this.offlineVideoId && !this.live && !this.playerReady) {
                        this.watchVideo({ id: this.offlineVideoId, title: this.offlineVideoTitle });
                    } else if (!this.offlineVideoId && !this.live && this.chosenVideo) {
                        this.closeVideo();
                    }
                })
                .onRequestError((err, handleErr) => {
                    handleErr(err, {
                        unauthorized: () => {
                            this.$requireLogin();
                        },
                        notFound: () => {
                            this.firstLoaded = true;
                            this.error = "not-found";
                        },
                        forbidden: () => {
                            this.firstLoaded = true;
                            this.error = "denied";
                        },
                        temporalError: () => {
                            // Retry
                            this.loading = true;
                            Timeouts.Set(this.loadRequestId, 1500, this.load.bind(this));
                        },
                    });
                })
                .onUnexpectedError((err) => {
                    console.error(err);
                    // Retry
                    this.loading = true;
                    Timeouts.Set(this.loadRequestId, 1500, this.load.bind(this));
                });
        },

        onRequestResolution: function (res, callback) {
            if (this.chosenVideo) {
                return this.onRequestResolutionVideo(res, callback);
            }

            Timeouts.Abort(this.loadResolutionRequestId);
            Request.Abort(this.loadResolutionRequestId);

            Request.Pending(this.loadResolutionRequestId, ApiStreaming.GetRoomRoomidSectionSectionidStreamingLiveResolution(this.room, this.sid, res, {}))
                .onSuccess((response) => {
                    callback(response.playlist);
                })
                .onRequestError((err, handleErr) => {
                    handleErr(err, {
                        unauthorized: () => {
                            this.$requireLogin();
                        },
                        notFound: () => {
                            this.error = "not-found";
                        },
                        forbidden: () => {
                            this.error = "denied";
                        },
                        temporalError: () => {
                            // Retry
                            Timeouts.Set(this.loadResolutionRequestId, 1500, this.onRequestResolution.bind(this)(res, callback));
                        },
                    });
                })
                .onUnexpectedError((err) => {
                    console.error(err);
                    // Retry
                    Timeouts.Set(this.loadResolutionRequestId, 1500, this.onRequestResolution.bind(this)(res, callback).bind(this));
                });
        },

        tick: function () {
            if (Date.now() - this.lastTimeSpectators > 20000) {
                this.loadSpectators();
            }

            this.el = document.getElementById("streaming-container-" + this.sid);

            if (!this.el) return;
            const w = this.el.offsetWidth;
            const h = this.el.offsetHeight;
            if (w < 480 || h < 360) {
                this.minPlayer = true;
            } else {
                this.minPlayer = false;
            }
        },

        onMediaError: function () {
            if (this.chosenVideo) {
                return this.onMediaErrorVideo();
            }
            this.load();
        },

        onStreamingChangeEvent: function (event) {
            if (this.sid === event.section) {
                this.load();
            }
        },

        onPlayerStall: function () {
            this.playerReady = false;
            if (!this.chosenVideo && !this.live && this.offlineVideoId) {
                this.watchVideo({id: this.offlineVideoId, title: this.offlineVideoTitle });
            }
        },

        onPlayerReady: function () {
            if (this.chosenVideo) {
                this.playerReady = false;
                return;
            }
            this.playerReady = true;
        },

        onRoomRoles: function (id, roles) {
            this.canAdmin = !!roles.admin;
        },

        getErrorMsg: function (x, error, firstLoaded) {
            if (!firstLoaded) {
                return this.$t("Loading channel...");
            } else if (error === "denied" || error === "not-found") {
                return this.$t("Could not load the streaming channel.");
            } else if (x) {
                return this.$t("This streaming channel is offline right now.");
            } else {
                return "";
            }
        },

        movePlayer: function(event) {
            event.preventDefault();

            this.el = document.getElementById("streaming-container-" + this.sid);
            this.mainContainer = document.getElementsByClassName("main-layout")[0];

            document.onmousedown = this.onMouseDown;
            document.onmousemove = this.onMouseMove;
            document.onmouseup = this.onMouseUp;

            this.$emit("start-move");
        },

        onMouseDown: function(e) {
            this.isDragReady = true;

            const pageX = e.pageX || e.clientX + (document.documentElement.scrollLeft ? document.documentElement.scrollLeft : this.mainContainer.scrollLeft);
            const pageY = e.pageY || e.clientY + (document.documentElement.scrollTop ? document.documentElement.scrollTop : this.mainContainer.scrollTop);
            this.dragoffset.x = pageX - this.el.offsetLeft;
            this.dragoffset.y = pageY - this.el.offsetTop;
        },

        onMouseMove: function(e) {
            let offsetX = 0;
            let offsetY = 0;
            if (this.isDragReady) {
                const pageX = e.pageX || e.clientX + (document.documentElement.scrollLeft ? document.documentElement.scrollLeft : this.mainContainer.scrollLeft);
                const pageY = e.pageY || e.clientY + (document.documentElement.scrollTop ? document.documentElement.scrollTop : this.mainContainer.scrollTop);
                   
                // left/right constraint
                if (pageX - this.dragoffset.x < 0) {
                    offsetX = 10;
                } else if (pageX - this.dragoffset.x + this.el.clientWidth > this.mainContainer.clientWidth) {
                    offsetX = this.mainContainer.clientWidth - this.el.clientWidth - 10;
                } else {
                    offsetX = pageX - this.dragoffset.x;
                }
                     
                // top/bottom constraint   
                if (pageY - this.dragoffset.y < 0) {
                    offsetY = 10;
                } else if (pageY - this.dragoffset.y + this.el.clientHeight > this.mainContainer.clientHeight) {
                    offsetY = this.mainContainer.clientHeight - this.el.clientHeight - 10;
                } else {
                    offsetY = pageY - this.dragoffset.y;
                }   
    
                this.el.style.top = offsetY + "px";
                this.el.style.left = offsetX + "px";
            }
        },

        onMouseUp: function() {
            this.isDragReady = false;
            document.onmousedown = null;
            document.onmousemove = null;
            document.onmouseup = null;
            this.$emit("end-move");
        },

        mouseLeaveContainer: function() {
            this.showConfigControls = false;
        },

        mouseEnterContainer: function() {
            this.showConfigControls = true;
        },

        closePlayer: function(event) {
            if (event) {
                event.stopPropagation();
            }
            this.$emit("hide-streaming", this.sid);
        },

        resizePlayer: function(event) {
            event.stopPropagation();

            this.el = document.getElementById("streaming-container-" + this.sid);
            this.mainContainer = document.getElementsByClassName("main-layout")[0];

            this.startSizeX = event.clientX;
            this.startSizeY = event.clientY;
            this.startSizeWidth = this.el.clientWidth;
            this.startSizeHeight = this.el.clientHeight;

            document.onmousemove = this.doDrag;
            document.onmouseup = this.stopDrag;

            this.$emit("start-move");        
        },

        doDrag: function(event) {
            const nextWidth = this.startSizeWidth - event.clientX + this.startSizeX;

            const elSC = document.getElementById("streaming-container-" + this.sid);

            if(nextWidth > 260) {
                const topV = elSC.getBoundingClientRect().top;
                const heightV = elSC.offsetHeight;
                const leftV = elSC.getBoundingClientRect().left;
                const widthV = elSC.offsetWidth;

                this.el.style.width = nextWidth + 'px';
                this.el.style.height = (this.el.clientWidth / 1.77) + 'px';

                const heightN = elSC.offsetHeight;
                const widthN = elSC.offsetWidth;
                
                elSC.style.top = (topV - (heightN - heightV)) + 'px';
                elSC.style.left = (leftV - (widthN - widthV)) + 'px';
            }
        },

        stopDrag: function() {
            document.onmousemove = null;
            document.onmouseup = null;

            this.$emit("end-move");
        },

        watchVideo: function (video) {
            this.vid = video.id;
            this.chosenVideoTitle = video.title;
            this.chosenVideo = true;
            this.playerReady = false;
            this.loadVideo();
            nextTick(() => {
                this.$el.scrollTop = 0;
            });
        },

        closeVideo: function () {
            Timeouts.Abort(this.loadResolutionRequestId);
            Request.Abort(this.loadResolutionRequestId);

            Timeouts.Abort(this.loadRequestId);
            Request.Abort(this.loadRequestId);

            this.vid = "";
            this.chosenVideo = false;
            this.chosenVideoTitle = "";
            if (this.live) {
                nextTick(() => {
                    this.$refs.hlsPlayer.setResolutions(this.resolutions);
                });
            } else if (this.offlineVideoId && !this.live) {
                this.watchVideo({id: this.offlineVideoId, title: this.offlineVideoTitle });
            }
        },

        loadVideo: function () {
            Timeouts.Abort(this.loadVideoResolutionRequestId);
            Request.Abort(this.loadVideoResolutionRequestId);

            Request.Pending(this.loadVideoRequestId, ApiStreaming.GetRoomRoomidSectionSectionidStreamingVideosVideoid(this.room, this.sid, this.vid, {}))
                .onSuccess((response) => {
                    if (response.resolutions.length > 0) {
                        nextTick(() => {
                            if (!this.chosenVideo) {
                                return;
                            }
                            this.$refs.hlsPlayer.setResolutions(response.resolutions);
                            this.$refs.hlsPlayer.setThumbnails(response.thumbnails);
                        });
                    } else {
                        this.$showSnackBar(this.$t("Could not load the video"));
                        this.closeVideo();
                    }
                })
                .onRequestError((err, handleErr) => {
                    handleErr(err, {
                        unauthorized: () => {
                            this.$requireLogin();
                            this.closeVideo();
                        },
                        notFound: () => {
                            this.$showSnackBar(this.$t("Could not load the video"));
                            this.closeVideo();
                        },
                        forbidden: () => {
                            this.$showSnackBar(this.$t("Could not load the video"));
                            this.closeVideo();
                        },
                        temporalError: () => {
                            this.$showSnackBar(this.$t("Could not load the video"));
                            this.closeVideo();
                        },
                    });
                })
                .onUnexpectedError((err) => {
                    console.error(err);
                    this.$showSnackBar(this.$t("Could not load the video"));
                    this.closeVideo();
                });
        },

        onRequestResolutionVideo: function (res, callback) {
            Timeouts.Abort(this.loadVideoResolutionRequestId);
            Request.Abort(this.loadVideoResolutionRequestId);

            Request.Pending(this.loadVideoResolutionRequestId, ApiStreaming.GetRoomRoomidSectionSectionidStreamingVideosVideoidResolution(this.room, this.sid, this.vid, res, {}))
                .onSuccess((response) => {
                    callback(response.playlist, response.download);
                })
                .onRequestError((err, handleErr) => {
                    handleErr(err, {
                        unauthorized: () => {
                            this.$requireLogin();
                        },
                        notFound: () => {
                            this.$showSnackBar(this.$t("Could not load the video"));
                            this.closeVideo();
                        },
                        forbidden: () => {
                            this.$showSnackBar(this.$t("Could not load the video"));
                            this.closeVideo();
                        },
                        temporalError: () => {
                            // Retry
                            Timeouts.Set(this.loadVideoResolutionRequestId, 1500, this.onRequestResolutionVideo.bind(this)(res, callback));
                        },
                    });
                })
                .onUnexpectedError((err) => {
                    console.error(err);
                    // Retry
                    Timeouts.Set(this.loadVideoResolutionRequestId, 1500, this.onRequestResolutionVideo.bind(this)(res, callback).bind(this));
                });
        },

        onMediaErrorVideo: function () {
            this.loadVideo();
        },
    },
    mounted: function () {
        this.$options.rolesH = this.onRoomRoles.bind(this);
        RoomController.on("room-roles", this.$options.rolesH);

        this.canAdmin = RoomController.admin;

        this.$options.streamingEH = this.onStreamingChangeEvent.bind(this);

        RoomController.on("streaming-start", this.$options.streamingEH);
        RoomController.on("streaming-end", this.$options.streamingEH);

        this.interval = setInterval(this.tick.bind(this), 100);

        this.loadInit();
    },
    beforeUnmount: function () {
        clearInterval(this.interval);

        RoomController.removeEventListener("room-roles", this.$options.rolesH);

        RoomController.removeEventListener("streaming-start", this.$options.streamingEH);
        RoomController.removeEventListener("streaming-end", this.$options.streamingEH);

        Timeouts.Abort(this.loadRequestId);
        Request.Abort(this.loadRequestId);

        Timeouts.Abort(this.loadVideoRequestId);
        Request.Abort(this.loadVideoRequestId);

        Timeouts.Abort(this.loadResolutionRequestId);
        Request.Abort(this.loadResolutionRequestId);

        Timeouts.Abort(this.loadVideoResolutionRequestId);
        Request.Abort(this.loadVideoResolutionRequestId);

        Timeouts.Abort(this.loadSpectatorsRequestId);
        Request.Abort(this.loadSpectatorsRequestId);

        Timeouts.Abort(this.loadStreamRecordingsListRequestId);
        Request.Abort(this.loadStreamRecordingsListRequestId);

        document.onmousemove = null;
        document.onmouseup = null;
    },
    watch: {
        display: function () {
            if(this.display) {
                this.loadInit();
            }
        },
    },
});
</script>

<style></style>
