<template>
    <div class="top-bar" tabindex="-1" :class="{ mini: !expanded }">
        <div class="dropdown w-100">
            <SearchBar @input="onSearch" @search="onSearch" :q="qPending" :placeholder="$t('Search a Room...')"/>
            <div v-if="showResults" class="dropdown-menu dropdown-menu-lg-end search-dropdown" :class="{'show': showResults}">
                <button class="btn btn-close-search" :title="$t('Close')" @click="closeSearch"><i class="fa-solid fa-xmark"></i></button>
                <RoomsList class="mt-5" :rooms="rooms" :more="more" :loading="loading" @load-more="loadMore" @select="selectRoom" :query="q"></RoomsList>
            </div>
        </div>
        <button class="create-button btn btn-primary" @click="showCreateRoom"> 
            <i v-if="sidebarExpanded" class="fa-solid fa-plus"></i>
            <span v-if="sidebarExpanded"> {{ $t("Create room") }}</span> 
            <img v-else src="/svg/add-room.svg" alt="add-room-icon">
        </button>
    </div>
</template>

<script lang="ts">
import { AuthController } from "@/control/auth";
import { defineComponent } from "vue";
import { Request } from "@asanrom/request-browser";
import { Timeouts } from "@/utils/timeout";

import SearchBar from '@/components/utils/SearchBar.vue';
import RoomsList from "@/components/utils/RoomsList.vue";

import { getUniqueStringId } from "@/utils/unique-id";
import { useVModel } from "@/utils/v-model";
import { saveIntoLocalStorage } from "@/utils/local-storage";

import { RoomController } from '@/control/room';
import { ApiRoom } from "@/api/api-group-room";

export default defineComponent({
    components: {
        SearchBar,
        RoomsList,
    },
    name: "TopBar",
    emits: ["update:expanded", "toggle-menu", "openModal"],
    props: {
        expanded: Boolean,
    },
    setup(props) {
        return {
            expandedStatus: useVModel(props, "expanded"),
            resizeObserver: null as ResizeObserver,
            loadRequestId: getUniqueStringId(),
        };
    },
    data: function () {
        return {
            platformName: import.meta.env.VITE__PLATFORM_NAME || "Platform",

            loggedIn: AuthController.isAuthenticated(),
            profileImage: AuthController.ProfileImage,
            profileName: AuthController.ProfileName || AuthController.Username || "",

            displayCreateRoom: false,

            sidebarExpanded: window.innerWidth > 992,

            isBackground: false,
            roomId: "",

            loading: false,
            more: true,
            rooms: [],
            q: "",
            qPending: "",
            skip: null, 
            showResults: false,
            next: false,
        };
    },
    methods: {
        openUserSettings: function () {
            this.$emit("openModal", "account-settings");
        },

        onAuthChanged: function () {
            this.loggedIn = AuthController.isAuthenticated();
            this.profileName = AuthController.ProfileName || AuthController.Username || "";
            this.profileImage = AuthController.ProfileImage;
        },

        renderName: function (name: string): string {
            return ((name + "").split(" ")[0] + "").split(",")[0] || "???";
        },

        closeSearch: function() {
            this.onSearch("");
        },

        handleClickOutsideSearch(event) {
            const dropdown = this.$el.querySelector('.dropdown-menu');
            if (this.showResults && dropdown && !dropdown.contains(event.target)) {
                this.closeSearch();
            }
        },

        onSearch(query: string) {
            if(!query) {
                this.qPending = "";
                this.q = "";
                this.loading = false;
                this.more = true;
                this.rooms = [];
                this.skip = null;
                this.showResults = false;
                return;
            }
            this.qPending = query;
            this.checkPendingQuery();
        },

        checkPendingQuery: function () {
            if (this.qPending != this.q) {
                this.q = this.qPending;
                this.onQueryChanged();
            }
        },

        onQueryChanged: function () {
            Request.Abort(this.loadRequestId);
            this.loading = false;
            this.more = true;
            this.rooms = [];
            this.skip = null;
            this.showResults = false;
            this.loadRooms();
        },

        loadMore: function() {
            this.loadRooms();
        },

        selectRoom: function(rid: string) {
            if(!rid) {
                return;
            }
            this.qPending = "";
            this.q = "";
            this.loading = false;
            this.more = true;
            this.rooms = [];
            this.skip = 0;
            this.showResults = false;
            RoomController.joinRoom(rid);
            saveIntoLocalStorage("father-id", "myrooms");
            this.$router.push({ name: "room", params: { roomId: rid } });
        },

        loadRooms: function() {
            if (this.loading || !this.more) {
                return;
            }

            let query = this.q;

            if (!query) {
                query = "*";
            }

            this.loading = true;

            let url = ApiRoom.GetRoomsSearch({ q: query});

            if(this.next) {
                url = ApiRoom.GetRoomsSearch({ q: query, skip: this.skip});
            }

            Request.Pending(this.loadRequestId, url)
                .onSuccess((response) => {
                    this.loading = false;
                    this.showResults = true;
                    for (let i = 0; i < response.rooms.length; i++) {
                        this.rooms.push(response.rooms[i]);
                    }

                    if (response.next) {
                        this.next = true;
                        this.more = true;
                        this.skip = response.skip;
                    } else {
                        this.next = false;
                        this.more = false;
                    }
                })
                .onRequestError((err, handleErr) => {
                    handleErr(err, {
                        unauthorized: () => {
                            this.$requireLogin();
                        },
                        temporalError: () => {
                            // Retry
                            Timeouts.Set(this.loadRequestId, 1500, this.loadRooms.bind(this));
                        },
                    });
                })
                .onUnexpectedError((err) => {
                    console.error(err);
                    // Retry
                    Timeouts.Set(this.loadRequestId, 1500, this.loadRooms.bind(this));
                });
        },

        showCreateRoom: function () {
            this.$emit("openModal", "create-room");
        },

        onPageResize: function () {
            this.sidebarExpanded = window.innerWidth > 992;
        },

        onRoomMetaUpdated: function (roomId, meta) {
            this.roomId = roomId;
            if (meta) {
                this.isBackground = meta.background !== "";
            }else {
                this.isBackground = false;
            }
        },
    },
    mounted: function () {
        this.$options.roomMetaHandler = this.onRoomMetaUpdated.bind(this);
        RoomController.on("room-meta", this.$options.roomMetaHandler);
        this.onRoomMetaUpdated(RoomController.room, RoomController.meta);

        document.addEventListener('click', this.handleClickOutsideSearch);

        this.$listenOnAppEvent("auth-status-changed", this.onAuthChanged.bind(this));
        if (window.ResizeObserver) {
            this.resizeObserver = new ResizeObserver(this.onPageResize.bind(this));
            this.resizeObserver.observe(this.$el);
        }
    },
    beforeUnmount: function () { 
        document.removeEventListener('click', this.handleClickOutsideSearch);

        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    },
});
</script>

<style scoped>
@import "@/style/layout/top-bar.css";
</style>
