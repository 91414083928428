import { AppEvents } from './app-events';
import { UserMediaController } from './user-media';
import { RoomController } from './room';
import { Request, RequestErrorHandler, abortNamedApiRequest } from "@asanrom/request-browser";
import { ExitPreventer } from '@/utils/exit-preventer';
import { ApiCalls } from '@/api/api-group-calls';
import { RecordingStartResponse } from '@/api/definitions';
import { CallsController } from './call';

function $t(s: string): string {
    return s;
}

export class RecordController {
    private static events: { [key: string]: Array<(...args: any[]) => void> } = {};
    public static recording: boolean = false;
    public static loading: boolean = false;
    public static room: string = "";
    public static videoId: string = "";
    public static wsRelay: string = "";
    public static token: string = "";

    private static stream: MediaStream | null = null;
    private static socket: WebSocket | null = null;
    private static interval: ReturnType<typeof setInterval> | null = null;
    private static mediaRecorder: MediaRecorder | null = null;

    private static startTime: number = 0;

    /* Init */

    public static init() {
        RoomController.on("room-roles", RecordController.onRoomRoles.bind(this));
        ExitPreventer.addPrevent("recording", ['exit-room', 'exit-page'], RecordController.onExitRoom.bind(this));
    }

    public static isSupported(): boolean {
        return UserMediaController.isDisplayMediaSupported() && !!window.MediaRecorder;
    }

    /* Events */

    private static onExitRoom(cont: () => void): boolean {
        if (!cont) {
            return RecordController.recording;
        }
        if (RecordController.recording) {
            AppEvents.Emit("ask-confirmation", {
                title: $t("Stop recording"),
                message: $t("If you leave the room you will also stop recording. Are you sure?"),
                callback: cont
            });
        } else {
            cont();
        }
        return true;
    }

    public static addEventListener(event: string, listener: (...args: any[]) => void) {
        if (!RecordController.events[event]) {
            RecordController.events[event] = [];
        }
        RecordController.events[event].push(listener);
    }

    public static on(event: string, listener: (...args: any[]) => void) {
        RecordController.addEventListener(event, listener);
    }

    public static removeEventListener(event: string, listener: (...args: any[]) => void) {
        if (!RecordController.events[event]) {
            return;
        }
        const i = RecordController.events[event].indexOf(listener);
        if (i >= 0) {
            RecordController.events[event].splice(i, 1);
            if (RecordController.events[event].length === 0) {
                delete RecordController.events[event];
            }
        }
    }

    public static emit(event: string, ...args: any[]) {
        if (!RecordController.events[event]) {
            return;
        }
        for (const listener of RecordController.events[event]) {
            listener(...args);
        }
    }

    /* Event handlers */

    private static onRoomRoles(id: string) {
        if (RecordController.recording && id !== RecordController.room) {
            RecordController.stopRecording();
        }
    }

    /* Action */

    public static startRecording(title: string, description: string) {
        if (RecordController.recording || !RoomController.room) {
            return;
        }

        RecordController.recording = true;
        RecordController.loading = true;
        RecordController.emit("loading", true);
        RecordController.room = RoomController.room;
        const handleRequestError = () => {
            RecordController.recording = false;
            RecordController.loading = false;
            RecordController.emit("loading", false);
    
            Request.Do(ApiCalls.PostRoomRoomidCallVideos(RecordController.room, { title, description }))
                .onRequestError((xhr: any) => {
                    new RequestErrorHandler()
                        .add(400, "TIME_LIMIT", () => {
                            RecordController.emit("message", $t("Error"), $t("This room reached the recording time limit for this month. On the first day next month, the time usage will be reset."));
                        })
                        .add(400, "ALREADY_RECORDING", () => {
                            RecordController.emit("message", $t("Error"), $t("There is already a recording in progress for this room. Stop it first in order to start a new one."));
                        })
                        .add(401, "*", () => {
                            RecordController.emit("message", $t("Error"), $t("Unauthorized: Your session token expired. Please, refresh the page to log back in."));
                        })
                        .add(403, "*", () => {
                            RecordController.emit("message", $t("Error"), $t("Access denied."));
                        })
                        .add(404, "*", () => {
                            RecordController.emit("message", $t("Error"), $t("Could not find the room."));
                        })
                        .add(0, "*", () => {
                            RecordController.emit("message", $t("Error"), $t("Could not connect to the server."));
                        })
                        .add("*", "*", () => {
                            const code = xhr.status || "UNKNOWN";
                            RecordController.emit("message", $t("Error"), $t("Unexpected error. Error code: ") + code);
                        })
                        .handle(xhr);
                });
        };
        // Create a new video
        Request.Do(ApiCalls.PostRoomRoomidCallVideos(RecordController.room, { title, description }))
        .onSuccess((response: RecordingStartResponse) => {
                RecordController.wsRelay = response.wsRelay;
                RecordController.token = response.token;
                RecordController.videoId = response.id;

                // Request user media
                UserMediaController.getDisplayMedia((stream: MediaStream | null) => {
                    if (!stream) {
                        RecordController.recording = false;
                        RecordController.emit("loading", false);
                        RecordController.removeVideo();
                        return; // User cancelled
                    }

                    RecordController.stream = stream;

                    UserMediaController.detectStreamEnding(RecordController.stream, RecordController.stopRecording.bind(RecordController));

                    // Connect to the websocket relay to start publishing
                    RecordController.socket = new WebSocket(RecordController.wsRelay);

                    RecordController.socket.onopen = function () {
                        // Send authenticator
                        if (RecordController.socket) {
                            RecordController.socket.send(RecordController.token);
                        }
                    };

                    RecordController.socket.onclose = function () {
                        RecordController.emit("message", $t("Error"), $t("The recording was interrupted due to a connection error."));
                        RecordController.socket = null;
                        if (RecordController.loading) {
                            RecordController.removeVideo();
                        }
                        RecordController.stopRecording();
                    };

                    // Keep the socket alive
                    RecordController.interval = setInterval(() => {
                        if (RecordController.socket) {
                            RecordController.socket.send("a");
                        }
                    }, 5000);

                    RecordController.socket.onmessage = function (event) {
                        const msg = event.data;
                        console.log("Message from Recording WS: " + msg);
                        if (msg === "READY") {
                            // Start recorder
                            console.log("Starting recording!");
                            RecordController.loading = false;
                            RecordController.emit("loading", false);
                            RecordController.startTime = Date.now();
                            RecordController.emit("start", RecordController.startTime);

                            RecordController.mediaRecorder = new MediaRecorder(stream);

                            RecordController.mediaRecorder.ondataavailable = function (e: BlobEvent) {
                                if (RecordController.socket) {
                                    RecordController.socket.send(e.data);
                                }
                            };

                            RecordController.mediaRecorder.start(1000);
                        }
                    };
                });
            })
            .onRequestError(handleRequestError)
            .onCancel(() => {
                RecordController.emit("message", $t("Info"), $t("The recording was cancelled"));
            })
            .onUnexpectedError((err) => {
                console.error(err);
            });
         
    }

    public static stopRecording() {
        if (!RecordController.recording) {
            return;
        }

        abortNamedApiRequest("recording-start-post");

        if (RecordController.mediaRecorder) {
            RecordController.mediaRecorder.stop();
            RecordController.mediaRecorder = null;
        }

        if (RecordController.stream) {
            UserMediaController.stopStream(RecordController.stream);
            RecordController.stream = null;
        }

        if (RecordController.interval) {
            clearInterval(RecordController.interval);
            RecordController.interval = null;
        }

        if (RecordController.socket) {
            RecordController.socket.onclose = null;
            RecordController.socket.close();
            RecordController.socket = null;
        }

        RecordController.startTime = 0;

        RecordController.emit("stop", RecordController.startTime);

        RecordController.recording = false;
        RecordController.loading = false;
        RecordController.emit("loading", false);
    }

    private static removeVideo() {
        const vid = RecordController.videoId;
        const roomId = RecordController.room;
    
        Request.Do(ApiCalls.DeleteRoomRoomidCallVideosVideoid(roomId, vid))
            .onSuccess(() => {
                console.log("Deleted video before start recording: " + vid);
            })
            .onRequestError((err) => {
                console.error("Failed to delete video: " + vid, err);
            })
            .onUnexpectedError((err) => {
                console.error("Unexpected error when deleting video: " + vid, err);
            });
    }

    public static startRecordingAudio() {
        //Media recorder
        const audioTracks = RecordController.stream.getAudioTracks();
        const audioStream = new MediaStream(audioTracks);
        const mediaRecorder = new MediaRecorder(audioStream);
        mediaRecorder.ondataavailable = event => {
            if (event.data.size > 0) {
                CallsController.audioChunks.push(event.data);
            }
        };
        mediaRecorder.start();
        mediaRecorder.onstop = () => {
            const audioBlob = new Blob(CallsController.audioChunks, { type: 'audio/wav' });
            const audioUrl = URL.createObjectURL(audioBlob);
            const downloadLink = document.createElement('a');
            downloadLink.href = audioUrl;
            downloadLink.download = 'grabacion.wav'; 
            downloadLink.click();
            URL.revokeObjectURL(audioUrl);
        };
        // mediaRecorder.stop(); al finalizar la llamada
        //END media recorder
            }
}

document.addEventListener('DOMContentLoaded', () => {
    RecordController.init();
});