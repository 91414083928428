<template>
    <ModalDialogContainer ref="modalContainer" v-model:display="displayStatus" @keydown="escapeToClose">
        <div class="modal-dialog h-75" role="document" @click="stopPropagationEvent">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title">{{ $t("Create new room") }}</div>
                    <button type="button" class="btn-close" :title="$t('Close')" @click="close"></button>
                </div>
                <div class="modal-body d-flex p-0 pe-3">
                    <div class="side-menu col-2 col-lg-3">
                        <router-link to="" :class="['side-bar-option', { 'selected': selectedDiv === 'new-room' }]"
                            @click.prevent="showDiv('new-room')">
                            <div class="side-bar-option-icon"><i class="fa-solid fa-plus"></i></div>
                            <div class="side-bar-option-text">{{ $t("Create room") }}</div>
                        </router-link>

                        <router-link to="" :class="['side-bar-option', { 'selected': selectedDiv === 'room-template' }]"
                            @click.prevent="showDiv('room-template')">
                            <div class="side-bar-option-icon"><i class="fa-solid fa-clone"></i></div>
                            <div class="side-bar-option-text">{{ $t("Room templates") }}</div>
                        </router-link>

                        <router-link to="" :class="['side-bar-option', { 'selected': selectedDiv === 'join-room' }]"
                            @click.prevent="showDiv('join-room')">
                            <div class="side-bar-option-icon"><i class="fa-solid fa-paperclip"></i></div>
                            <div class="side-bar-option-text">{{ $t("Join an existing room") }}</div>
                        </router-link>
                    </div>
                    <div class="room-content col-10 col-lg-9 ps-3">
                        <div v-if="visibleDiv === 'new-room'">
                            <label class="headline-modal">{{ $t("Room name") }}</label>
                            <input type="text" name="room-title" autocomplete="off" v-model="roomName"
                                class="form-control" maxlength="80" />
                            <div v-if="error_name" class="error-message">{{ error_name }}</div>
                            <label class="headline-modal">{{ $t("Room description") }}</label>
                            <textarea name="room-description" autocomplete="off" v-model="roomDescription"
                                class="form-control" maxlength="255">
                            </textarea>

                            <label class="headline-modal">{{ $t("Create under the responsibility of") }}</label>
                            <select name="room-template" v-model="roomTemplate" class="form-select">
                                <option value="">{{ username }}</option>
                            </select>
                            <div v-if="error" class="error-message">{{ error }}</div>
                            <div class="btn-bottom-modal">
                                <button type="button" class="btn btn-primary" @click="createRoom">
                                    <i class="fa-solid fa-plus"></i>{{ $t("Create room") }}
                                </button>
                            </div>
                        </div>
                        <label class="headline-modal" v-if="visibleDiv === 'new-room'">{{ $t("Choose privacy for the room") }}</label>
                        <form class="w-100" v-if="visibleDiv === 'new-room'">
                            <div class="form-check d-flex" @click="toggleCheck(true)" >
                                <div class="d-flex">
                                    <div class="me-2 cursor-pointer">
                                        <i class="fa-solid fa-circle-dot" v-if="selectedPrivacyMode"></i>
                                        <i class="fa-regular fa-circle" v-else></i>
                                    </div>
                                    <label class="form-check-label p-0 cursor-pointer" :class="{ selectedCheck: selectedPrivacyMode }">
                                        {{ $t("Public") }}
                                    </label>
                                </div>
                                <div class="txt-info italic ps-3" v-if="selectedPrivacyMode"> ( {{ privacyText }} )</div>

                            </div>
                            <div class="form-check d-flex cursor-pointer" @click="toggleCheck(false)">
                                <div class="d-flex ">
                                    <div  class="me-2 cursor-pointer">
                                        <i class="fa-solid fa-circle-dot" v-if="!selectedPrivacyMode"></i>
                                        <i class="fa-regular fa-circle" v-else></i>
                                    </div>
                                    <label class="form-check-label p-0  cursor-pointer" :class="{ selectedCheck: !selectedPrivacyMode }">
                                        {{ $t("Private") }}
                                    </label>
                                </div>
                                <div class="txt-info italic ps-3" v-if="!selectedPrivacyMode">( {{ privacyText }} )</div>
                            </div>
                        </form>
                        <div class="error-message" v-if="error_privacy">{{ error_privacy }}</div>
                        <div v-if="visibleDiv === 'room-templates'">
                        </div>

                        <div v-if="visibleDiv === 'join-room'">
                            <form>
                                <div class="form-group">
                                    <label class="headline-modal"> {{ $t("Type your invite link or code here")
                                        }}</label>
                                    <input type="text" :disabled="requesting" name="invite-code" autocomplete="off"
                                        v-model="code" class="form-control" maxlength="255" />
                                </div>
                                <div v-if="isValidLink" class="room-container mt-2 mb-2">
                                    <label>{{ $t("Using the code above, you will join the room") }}</label>
                                </div>
                                <div v-if="isValidLink" class="room-container mb-3">
                                    <div class="row">
                                        <div
                                            class="col-lg-3 col-sm-12 pt-3 d-flex justify-content-center align-items-center">
                                            <img v-if="roomPicture" class="img-fluid rounded-image" :src="roomPicture"
                                                alt="placeholder" />
                                            <img v-else class="img-fluid rounded-image" src="https://via.placeholder.com/150"
                                                alt="placeholder" />
                                        </div>
                                        <div class="col-lg-9 pt-4 col-sm-12 p-2 justify-content-center align-items-center">
                                            <div class="row">
                                                <div class="col-8 d-flex justify-content-center justify-content-sm-start align-items-start title-option">
                                                    {{ roomName }}</div>
                                                <div v-if="isPublic" class="col-2 room-privacy d-flex justify-content-start">
                                                    {{ $t("(Public)") }}</div>
                                                <div v-if="!isPublic" class="room-privacy d-flex justify-content-start">
                                                    {{ $t("Private") }}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 d-none d-sm-block">{{ roomDescription }}</div>
                                                <div class="room-list-item-date"> {{ $t("Creation date:") }} {{
                                                    renderDate(roomCreated) }}</div>
                                            </div>
                                        </div>
                                        <div v-if="waitingForMods">
                                            <label> {{ $t("Waiting for room moderation to accept you") }} <i
                                                    class="fa fa-spinner fa-spin"></i></label>
                                            <button type="button" @click="close" class="btn btn-cancel"> {{ $t("Cancel")}}</button>
                                        </div>
                                    </div>
                                    <div v-if="error" class="error-message">{{ error }}</div>
                                </div>
                                <div class="btn-bottom-modal">
                                    <button type="button" class="btn btn-primary" :disabled="requesting"  @click="submit">
                                        <i class="fa-solid fa-paperclip"></i>{{ $t("Join room") }}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ModalDialogContainer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "../../utils/v-model";
import { ApiRoom } from "@/api/api-group-room";
import { AppEvents } from "@/control/app-events";
import { Request } from "@asanrom/request-browser";
import { NewRoomRequest } from "@/api/definitions";
import { getUniqueStringId } from "@/utils/unique-id";
import { AuthController } from "@/control/auth";
import { Timeouts } from "@/utils/timeout";
import { RoomController } from "@/control/room";
import { renderDateAndTime } from "@/utils/time-utils";

export default defineComponent({
    name: "CreateRoomModal",
    emits: ["update:display"],
    props: {
        display: Boolean,
        icode: String,
    },
    setup(props) {
        return {
            displayStatus: useVModel(props, "display"),

            codeRequestId: getUniqueStringId(),
            submitRequestId: getUniqueStringId(),
            checkRequestId: getUniqueStringId(),
            moderatorAccessRequestId: getUniqueStringId(),
        };
    },
    data: function () {
        return {
            roomName: "",
            roomDescription: "",
            roomTemplate: "",
            roomPicture: "",
            roomCreated: 0,
            roomOwner: "",
            isPublic: false,
            isValidLink: false,
            loading: false,
            notFound: false,
            busy: false,
            username: AuthController.Username,
            tfa: false,
            error: "",
            error_name: "",
            error_privacy: "",
            visibleDiv: "new-room",
            selectedDiv: "new-room",
            error_description: "",

            selectedPrivacyMode: false,
            privacyText: this.$t("Nobody can enter in the room"),

            // Join room
            code: "",
            requesting: false,
            joiningTo: "",
            waitingForMods: false,
        };
    },
    methods: {
        renderDate: function (d) {
            return renderDateAndTime(d, this.$t);
        },
        
        close: function () {
            this.displayStatus = false;
        },

        escapeToClose: function (event) {
            if (event.key === "Escape") {
                this.displayStatus = false;
            }
        },

        stopPropagationEvent: function (e) {
            e.stopPropagation();
        },

        toggleCheck: function (mode) {
            this.selectedPrivacyMode = mode;

            switch (mode) {
                case true:
                    this.privacyText = this.$t("Anyone can enter in the room");
                    break;
                case false:
                    this.privacyText = this.$t("Nobody can enter in the room");
                    break;
            }
        },

        createRoom: function () {
            if (this.busy) {
                return;
            }

            if (!this.roomName) {
                this.error = this.$t("The room name is required");
                return;
            }

            const request: NewRoomRequest = {
                name: this.roomName,
                description: this.roomDescription,
                isPublic: this.selectedPrivacyMode,
            };

            Request.Do(ApiRoom.PostRoom(request))
                .onSuccess((response) => {
                    this.busy = false;
                    this.$router.push({ name: "room", params: { roomId: response.room_id } });
                    this.$showSnackBar(this.$t("Room added to the list"));
                    this.reset();
                    this.close();
                })
                .onRequestError((err, handleErr) => {
                    this.busy = false;
                    handleErr(err, {
                        unauthorized: () => {
                            AppEvents.Emit("Unauthorized");
                            this.error = this.$t("Access denied");
                        },
                        badRequestTooMany: () => {
                            this.error = this.$t("Bad request");
                        },
                        badRequestInvalidTemplate: () => {
                            this.error = this.$t("Bad request");
                        },
                        badRequestInvalidDescription: () => {
                            this.error_description = this.$t("Description should be between 3 and 255 characters");
                        },
                        badRequestInvalidPublicMode: () => {
                            this.error_description = this.$t("You must select a privacy mode");
                        },
                        badRequestInvalidName: () => {
                            this.error_name = this.$t("Name should be between 3 and 80 characters");
                        },
                        badRequestInvalidOrganization: () => {
                            this.error = this.$t("Bad request");
                        },
                        badRequest: () => {
                            this.error = this.$t("Bad request");
                        },
                        serverError: () => {
                            this.error = this.$t("Internal server error");
                        },
                        networkError: () => {
                            this.error = this.$t("Could not connect to the server");
                        },
                    });
                })
                .onUnexpectedError((err) => {
                    this.busy = false;
                    console.error(err);
                    this.error = err.message;
                });
        },

        reset: function () {
            this.error = "";
            this.roomName = "";
            this.roomDescription = "";
        },

        showDiv: function (divName: string) {
            this.reset();
            this.visibleDiv = divName;
            this.selectedDiv = divName;
        },

        getCodeFormURL: function (url) {
            try {
                const parsed = new URL(url);
                const path = parsed.pathname;
                const parts = path.split("/");
                const roomId = parts[2] || "";
                const code = parsed.searchParams.get("code") || "";
                return {
                    room: roomId,
                    code: code,
                };
            } catch (ex) {
                // No url
                return {
                    room: url,
                    code: "",
                };
            }
        },

        submit: function (event) {
            if (event) {
                event.preventDefault();
            }

            if (this.requesting || !this.code) {
                return;
            }

            this.error = "";
            const code = this.getCodeFormURL(this.code);
            this.requesting = true;
            this.joiningTo = "";

            Request.Pending(this.submitRequestId, ApiRoom.GetRoomsJoinRoom(code.room, { code: code.code }))
                .onSuccess((response) => {
                    this.joiningTo = response.id;
                    if (response.canJoin) {
                        this.requesting = false;
                        this.close();
                        RoomController.joinRoom(this.joiningTo);
                        this.$router.push({ name: "room", params: { roomId: code.room } });
                    } else if (response.canRequest) {
                        // We request access
                        this.waitingForMods = true;
                        this.requestAccess();
                    } else if (response.banned) {
                        this.requesting = false;
                        this.error = this.$t("You are banned from the room.");
                    } else {
                        this.requesting = false;
                        this.error = this.$t("The link points to a private room. You will need to contact the room staff to be able to join.");
                    }
                })
                .onRequestError((err, handleErr) => {
                    this.requesting = false;
                    handleErr(err, {
                        unauthorized: () => {
                            this.$requireLogin();
                        },
                        notFound: () => {
                            this.error = this.$t("Room not found");
                        },
                        serverError: () => {
                            this.error = this.$t("Internal server error");
                        },
                        networkError: () => {
                            this.error = this.$t("Could not connect to the server");
                        },
                    });
                })
                .onUnexpectedError((err) => {
                    this.requesting = false;
                    console.error(err);
                    this.error = err.message;
                });
        },

        checkIfAccepted: function () {
            Request.Pending(this.checkRequestId, ApiRoom.GetRoomsJoinRoom(this.joiningTo, {}))
                .onSuccess((response) => {
                    if (response.canJoin) {
                        // Join the room
                        this.requesting = false;
                        this.waitingForMods = false;
                        this.close();
                        RoomController.joinRoom(this.joiningTo);
                        this.$router.push({ name: "room", params: { roomId: this.joiningTo } });
                    } else if (response.banned) {
                        this.requesting = false;
                        this.waitingForMods = false;
                        this.error = this.$t("You are banned from the room.");
                    } else {
                        // Try again later
                        Timeouts.Set(this.checkRequestId, 5000, this.checkIfAccepted.bind(this));
                    }
                })
                .onRequestError((err, handleErr) => {
                    this.requesting = false;
                    handleErr(err, {
                        unauthorized: () => {
                            this.$requireLogin();
                        },
                        notFound: () => {
                            this.error = this.$t("Room not found");
                        },
                        serverError: () => {
                            this.error = this.$t("Internal server error");
                        },
                        networkError: () => {
                            this.error = this.$t("Could not connect to the server");
                        },
                    });
                })
                .onUnexpectedError((err) => {
                    this.requesting = false;
                    console.error(err);
                    this.error = err.message;
                });
        },

        requestAccess: function () {
            Request.Pending(this.moderatorAccessRequestId, ApiRoom.PostRoomRoomidJoinRequest(this.joiningTo))
                .onSuccess(() => {
                    Timeouts.Set(this.moderatorAccessRequestId, 5000, this.checkIfAccepted.bind(this));
                })
                .onRequestError((err, handleErr) => {
                    this.waitingForMods = false;
                    this.requesting = false;
                    handleErr(err, {
                        notFound: () => {
                            this.error = this.$t("Room not found");
                        },
                        forbiddenCooldown: () => {
                            this.error = this.$t("You are banned from the room.");
                        },
                        forbiddenBanned: () => {
                            this.error = this.$t("You are banned from the room.");
                        },
                        forbiddenCantRequest: () => {
                            this.error = this.$t("You can't request access to this room.");
                        },
                        forbidden: () => {
                            this.error = this.$t("You can't request access to this room.");
                        },
                        unauthorized: () => {
                            this.$requireLogin();
                        },
                        badRequest: () => {
                            this.error = this.$t("Bad request");
                        },
                        serverError: () => {
                            this.error = this.$t("Internal server error");
                        },
                        networkError: () => {
                            this.error = this.$t("Could not connect to the server");
                        },
                    });
                })
                .onUnexpectedError((err) => {
                    this.requesting = false;
                    console.error(err);
                    this.error = err.message;
                });
        },
    },
    mounted: function () {
        this.code = this.icode;
        if(this.code) {
            this.showDiv('join-room');
        }

        if (this.display) {
            this.$autoFocus();
        }

        if (AuthController.isAuthenticated()) {
            this.$autoFocus();
        } else {
            this.$requireLogin();
        }
    },
    beforeUnmount: function () {
        Timeouts.Abort(this.codeRequestId);
        Request.Abort(this.codeRequestId);
        Timeouts.Abort(this.submitRequestId);
        Request.Abort(this.submitRequestId);
        Timeouts.Abort(this.checkRequestId);
        Request.Abort(this.checkRequestId);
        Timeouts.Abort(this.moderatorAccessRequestId);
        Request.Abort(this.moderatorAccessRequestId);
        this.requesting = false;
        this.waitingForMods = false;
        this.joiningTo = "";
    },
    watch: {

        code: function () {
            const code = this.getCodeFormURL(this.code);
            Request.Pending(this.codeRequestId, ApiRoom.GetRoomsInfoRoom(code.room))
                .onSuccess((response) => {
                    if (response.result === "SUCCESS") {
                        this.roomPicture = response.picture;
                        this.roomName = response.name;
                        this.roomDescription = response.description;
                        this.isPublic = response.isPublic;
                        this.roomCreated = response.created;
                        this.roomOwner = response.owner.username;
                        this.isValidLink = true;
                    } else {
                        this.isValidLink = false;
                        this.roomPicture = "";
                        this.roomName = "";
                        this.roomDescription = "";
                        this.isPublic = false;
                        this.roomOwner = null;
                        this.roomCreated = 0;
                    }
                })
                .onRequestError(() => {
                    this.isValidLink = false;
                    this.roomPicture = "";
                    this.roomName = "";
                    this.roomDescription = "";
                    this.isPublic = false;
                    this.roomCreated = 0;
                })
                .onUnexpectedError(() => {
                    this.isValidLink = false;
                    this.roomPicture = "";
                    this.roomName = "";
                    this.roomDescription = "";
                    this.isPublic = false;
                    this.roomCreated = 0;
                });
        },
    },
});
</script>
<style scoped>
@import "@/style/modals/create-room.css";

</style>
