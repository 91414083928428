/**
 * Chat controller
 * 
 * Events:
 *  - 'chat-reload' (room) - Emits when chat requires being reloaded (room is changed)
 *  - 'chat-message' (event) - Normal chat message
 *  - 'chat-notice'  (time, target: 'all' | 'mod' | 'admin', message)
 *  - 'chat-error' (message, private)
 *  - 'chat-delete-msg' (id)
 *  - 'chat-delete-user' (uid)
 * 
 * Methods:
 *   - send (message) - Send a message
 */

import { AppEvents } from './app-events';
import { RoomController } from './room';
import { WebSocketController } from './socket';
import { RecordController } from './record';

function $t(s: string): string {
    return s;
}

export class ChatController {
    public static room: string = "";
    private static events: { [key: string]: Array<(...args: any[]) => void> } = {};

    public static init() {
        ChatController.room = RoomController.room;

        RoomController.on("room-roles", ChatController.onRoomChanged.bind(this));

        RoomController.on("chat", ChatController.onChat.bind(this));
        RoomController.on("chat-error", ChatController.onChatError.bind(this));
        RoomController.on("chat-delete", ChatController.onChatDelete.bind(this));

        AppEvents.AddEventListener("connect", ChatController.onSocketReconnect.bind(this));
    }

    /* Send */

    public static send(message: string, priv?: boolean) {
        message = message.trim();

        if (!message) {
            return;
        }

        if (!WebSocketController.connected) {
            ChatController.emit('chat-error', $t("Error: There is not a connection to the server."));
            return;
        }

        if (ChatController.parseCommands(message)) {
            return; // Is a command
        }

        WebSocketController.send({
            type: 'chat',
            message: message,
            private: priv,
        });
    }

    private static parseCommands(message: string): boolean {
        return CommandParser.create()
            .add("*", () => {
                ChatController.emit('chat-error', $t("Unknown command. To type a message that starts with '/', type it with '//'. Example: //message"));
            })
            .parse(message);
    }

    /* Events */

    public static addEventListener(event: string, listener: (...args: any[]) => void) {
        if (!ChatController.events[event]) {
            ChatController.events[event] = [];
        }
        ChatController.events[event].push(listener);
    }

    public static on(event: string, listener: (...args: any[]) => void) {
        ChatController.addEventListener(event, listener);
    }

    public static removeEventListener(event: string, listener: (...args: any[]) => void) {
        if (!ChatController.events[event]) {
            return;
        }
        const i = ChatController.events[event].indexOf(listener);
        if (i >= 0) {
            ChatController.events[event].splice(i, 1);
            if (ChatController.events[event].length === 0) {
                delete ChatController.events[event];
            }
        }
    }

    public static emit(event: string, ...args: any[]) {
        if (!ChatController.events[event]) {
            return;
        }
        for (const listener of ChatController.events[event]) {
            listener(...args);
        }
    }

    /* Event handlers */

    private static onSocketReconnect() {
        if (ChatController.room) {
            ChatController.emit('chat-reload', ChatController.room);
        }
    }

    private static onRoomChanged(room: string) {
        if (ChatController.room !== room) {
            ChatController.room = room;
            ChatController.emit('chat-reload', ChatController.room);
        }
    }

    private static checkChatMessageForNotices(event: any): boolean {
        return CommandParser.create()
            .add("mute", (arg: string) => {
                const target = arg.split("|")[1] || ("[" + $t("Deleted user") + "]");
                ChatController.emit("chat-notice", event.timestamp, 'mod', target + " " + $t("was muted by") + " " + event.user_name, event.private);
            })
            .add("unmute", (arg: string) => {
                const target = arg.split("|")[1] || ("[" + $t("Deleted user") + "]");
                ChatController.emit("chat-notice", event.timestamp, 'mod', target + " " + $t("was unmuted by") + " " + event.user_name, event.private);
            })
            .add("ban", (arg: string) => {
                const target = arg.split("|")[1] || ("[" + $t("Deleted user") + "]");
                ChatController.emit("chat-notice", event.timestamp, 'mod', target + " " + $t("was banned by") + " " + event.user_name, event.private);
            })
            .add("unban", (arg: string) => {
                const target = arg.split("|")[1] || ("[" + $t("Deleted user") + "]");
                ChatController.emit("chat-notice", event.timestamp, 'mod', target + " " + $t("was unbanned by") + " " + event.user_name, event.private);
            })
            .add("kick", (arg: string) => {
                const target = arg.split("|")[1] || ("[" + $t("Deleted user") + "]");
                ChatController.emit("chat-notice", event.timestamp, 'mod', target + " " + $t("was kicked from the call by") + " " + event.user_name, event.private);
            })
            .add("deletemsg", () => {
                ChatController.emit("chat-notice", event.timestamp, 'mod', event.user_name + " " + $t("deleted a message"), event.private);
            })
            .add("modchat", (arg: string) => {
                if (arg === "off") {
                    ChatController.emit("chat-notice", event.timestamp, 'mod', event.user_name + " " + $t("disabled the moderated chat"), event.private);
                } else {
                    ChatController.emit("chat-notice", event.timestamp, 'mod', event.user_name + " " + $t("enabled the moderated chat"), event.private);
                }
            })
            .add("slowchat", (arg: string) => {
                if (arg === "off") {
                    ChatController.emit("chat-notice", event.timestamp, 'mod', event.user_name + " " + $t("disabled the slow chat"), event.private);
                } else {
                    ChatController.emit("chat-notice", event.timestamp, 'mod', event.user_name + " " + $t("enabled the slow chat"), event.private);
                }
            })
            .add("recordstart", () => {
                ChatController.emit("chat-notice", event.timestamp, 'mod', event.user_name + " " + $t("started recording the room"), event.private);
                RecordController.emit('rec-status-alert');
            })
            .add("recordstop", () => {
                ChatController.emit("chat-notice", event.timestamp, 'mod', event.user_name + " " + $t("stopped recording the room"), event.private);
                RecordController.emit('rec-status-alert');
            })
            .add("*", () => {
                console.error("Unknown notice: " + JSON.stringify(event));
            })
            .parse(event.message);
    }

    private static onChat(event: any) {
        if (ChatController.checkChatMessageForNotices(event)) {
            return;
        }

        ChatController.emit('chat-message', event);
    }

    private static onChatError(event: any) {
        switch (event.type) {
            case "MUTED_CANT_CHAT":
                ChatController.emit('chat-error', $t("Error: You are muted and cannot chat."), event.private);
                break;
            case "MODCHAT_CANT_CHAT":
                ChatController.emit('chat-error', $t("Error: The chat is restricted and you lack the permission to send messages."), event.private);
                break;
            case "COOLDOWN":
                ChatController.emit('chat-error', $t("Error: Due to slow chat restriction, you have to wait 10 seconds between messages."), event.private);
                break;
            default:
                ChatController.emit('chat-error', $t("Error") + ": " + event.type, event.private);
        }
    }

    private static onChatDelete(event: any) {
        if (event.mode === "msg") {
            ChatController.emit("chat-delete-msg", event.msg_id);
        } else if (event.mode === "user") {
            ChatController.emit("chat-delete-user", event.user_id);
        }
    }
}

export class CommandParser {
    private cmds: Array<{ cmd: string, callback: (arg: string) => void }> = [];

    public static create(): CommandParser {
        return new CommandParser();
    }

    public add(cmd: string, callback: (arg: string) => void): this {
        this.cmds.push({ cmd, callback });
        return this;
    }

    public parse(message: string): boolean {
        if (message.charAt(0) === '/' && message.charAt(1) !== '/') {
            const indexOfSpace = message.indexOf(' ');
            let cmd = message.substr(1);
            let arg: string = "";

            if (indexOfSpace > 0) {
                arg = cmd.substr(indexOfSpace);
                cmd = cmd.substr(0, indexOfSpace - 1);
            }

            cmd = cmd.toLowerCase();

            //console.log("Command: " + cmd + " with arg: " + arg);

            for (const command of this.cmds) {
                if (cmd === command.cmd || command.cmd === "*") {
                    command.callback(arg);
                    return true;
                }
            }

            return false;
        } else {
            return false; // Not a command
        }
    }
}

