// Element in view check
"use strict";

export function elementInView(el: Element): boolean {
    const scroll = window.scrollY;
    const boundsTop = el.getBoundingClientRect().top + scroll;
    const viewport = {
        top: scroll,
        bottom: scroll + window.innerHeight,
    };
    const bounds = {
        top: boundsTop,
        bottom: boundsTop + el.clientHeight,
    };
    return (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) || (bounds.top <= viewport.bottom && bounds.top >= viewport.top);
}