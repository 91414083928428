/**
 * User media controller
 * 
 * Controls user media input:
 *  - Camera
 *  - Microphone
 *  - Screen sharing
 */

export class UserMediaController {

    public static userMediaOptions = {
        video: true,
        audio: true,
    };

    public static userMediaStream = null;

    public static displayMediaOptions = {
        video: true,
        audio: true,
    };

    public static displayMediaStream = null;

    public static placeholderCanvas = null;

    public static Initialize() {
        const placeholderCanvas = document.createElement("canvas");
        placeholderCanvas.className = "placeholder-canvas";
        document.body.appendChild(placeholderCanvas);
        this.placeholderCanvas = placeholderCanvas;
    }

    public static isUserMediaSupported() {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            return true;
        }
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            // Cam not supported
            return false;
        }

        return true;
    }

    public static isDisplayMediaSupported() {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getDisplayMedia) {
            // Screen share not supported
            return false;
        }

        return true;
    }

    /* Media */

    public static stopStream(stream) {
        try {
            stream.stop();
        } catch (ex) { undefined }
        try {
            stream.getTracks().forEach(track => {
                track.stop();
            });
        } catch (ex) { undefined }
    }

    public static stopVideo(stream) {
        try {
            stream.getTracks().forEach((track) => {
                if (track.kind === "video") {
                    track.stop();
                    stream.removeTrack(track);
                }
            });
        } catch (ex) { undefined }
    }

    public static injectVideo(stream, streamToInject) {
        streamToInject.getTracks().forEach(track => {
            if (track.kind === "video") {
                stream.addTrack(track);
            }
        });
    }

    public static getPlaceholderStream() {
        this.placeholderCanvas.getContext("2d");
        return this.placeholderCanvas.captureStream(25);
    }

    public static getMedia(reqFunc, audio, video, callback) {
        if (!audio && !video) {
            return callback(null);
        }
        let options = {
            audio: audio,
            video: video,
        };
        // First we try both
        reqFunc(options, (stream) =>{
            callback(stream);
        }, (err) => {
            console.error(err);
            if (err.name === "NotAllowedError") {
                return callback(null, err.message);
            }
            // Now we try only audio
            options = {
                audio: audio,
                video: false,
            };
            reqFunc(options, (stream) => {
                callback(stream);
            }, (err2) => {
                console.error(err2);
                if (err2.name === "NotAllowedError") {
                    return callback(null, err2.message);
                }
                // Now we try only video
                options = {
                    audio: false,
                    video: video,
                };
                reqFunc(options, (stream) => {
                    callback(stream);
                }, (err3) => {
                    console.error(err3);
                    if (err3.name === "NotAllowedError") {
                        return callback(null, err3.message);
                    }
                    // No way to get the stream
                    try {
                        callback(UserMediaController.getPlaceholderStream());
                    } catch (ex) {
                        callback(null);
                    }
                });
            });
        });
    }

    public static getUserMedia(cb) {
        const reqFunc = (options, callback, errCallback) => {
            try {
                navigator.mediaDevices.getUserMedia(options)
                    .then(callback)
                    .catch(errCallback);
            } catch (ex) {
                try {
                    navigator.mediaDevices.getUserMedia(options) // Deprecated
                        .then(callback)
                        .catch(errCallback);
                } catch (ex2) {
                    errCallback(ex2);
                }
            }
        };

        this.getMedia(reqFunc, this.userMediaOptions.audio, this.userMediaOptions.video, (stream) => {
            cb(stream);
        });
    }

    public static getUserMediaOnlyVideo (cb) {
        const reqFunc = (options, callback, errCallback) => {
            try {
                navigator.mediaDevices.getUserMedia(options)
                    .then(callback)
                    .catch(errCallback);
            } catch (ex) {
                try {
                    navigator.mediaDevices.getUserMedia(options) // Deprecated
                        .then(callback)
                        .catch(errCallback);
                } catch (ex2) {
                    errCallback(ex2);
                }
            }
        };

        this.getMedia(reqFunc, false, true, (stream) => {
            cb(stream);
        });
    }

    public static getDisplayMedia(cb) {
        const reqFunc = (options, callback, errCallback) => {
            try {
                navigator.mediaDevices.getDisplayMedia(options)
                    .then(callback)
                    .catch(errCallback);
            } catch (ex) {
                errCallback(ex);
            }
        };

        this.getMedia(reqFunc, this.displayMediaOptions.audio, this.displayMediaOptions.video, (stream, ex) => {
            cb(stream, ex);
        });
    }

    public static checkMediaStreamVideo(stream) {
        if (!stream) return false;
        const tracks = stream.getTracks();
        for (let i = 0; i < tracks.length; i++) {
            if (tracks[i].kind === "video") {
                return true;
            }
        }
        return false;
    }

    public static checkMediaStreamAudio(stream) {
        if (!stream) return false;
        const tracks = stream.getTracks();
        for (let i = 0; i < tracks.length; i++) {
            if (tracks[i].kind === "audio") {
                return true;
            }
        }
        return false;
    }

    public static setMediaStreamVideoEnabled(stream, enabled) {
        if (!stream) return;
        const tracks = stream.getTracks();
        for (let i = 0; i < tracks.length; i++) {
            if (tracks[i].kind === "video") {
                tracks[i].enabled = !!enabled;
            }
        }
    }

    public static setMediaStreamAudioEnabled(stream, enabled) {
        if (!stream) return;
        const tracks = stream.getTracks();
        for (let i = 0; i < tracks.length; i++) {
            if (tracks[i].kind === "audio") {
                tracks[i].enabled = !!enabled;
            }
        }
    }

    public static detectStreamEnding(stream, handler) {
        const tracks = stream.getTracks();
        if (tracks.length > 0) {
            tracks[0].addEventListener("ended", handler);
        }
    }

    public static removeVideoFromStream (stream) {
        const tracks = stream.getTracks();
        for (let i = 0; i < tracks.length; i++) {
            if (tracks[i].kind === "audio") {
                return new MediaStream([tracks[i]]);
            }
        }

        return new MediaStream();
    }
}
