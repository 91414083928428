// Extiende la interfaz Window para incluir ExitPreventer
declare global {
    interface Window {
        ExitPreventer: ExitPreventerInterface;
    }
}

interface PreventHandler {
    id: string;
    types: string[];
    handler: (cont: (() => void) | null) => boolean | void;
}

interface ExitPreventerInterface {
    prevents: { [key: string]: PreventHandler };

    addPrevent(id: string, types: string[], handler: (cont: () => void) => void): void;
    removePrevent(id: string): void;
    handleNext(handlers: PreventHandler[], cont: () => void): void;
    handlePrevent(type: string, cont: () => void): void;
    handlePreventSync(type: string): boolean;
}

export const ExitPreventer: ExitPreventerInterface = {
    prevents: {},

    addPrevent(id: string, types: string[], handler: (cont: () => void) => void): void {
        this.prevents[id] = {
            id: id,
            types: types,
            handler: handler,
        };
    },

    removePrevent(id: string): void {
        delete this.prevents[id];
    },

    handleNext(handlers: PreventHandler[], cont: () => void): void {
        if (handlers.length === 0) {
            return cont();
        }

        const h = handlers.pop()!;

        h.handler(() => {
            this.handleNext(handlers, cont);
        });
    },

    handlePrevent(type: string, cont: () => void): void {
        const handlers: PreventHandler[] = [];

        for (const id in this.prevents) {
            const h = this.prevents[id];
            if (h.types && h.types.indexOf(type) >= 0) {
                handlers.push(h);
            }
        }

        this.handleNext(handlers, cont);
    },

    handlePreventSync(type: string): boolean {
        let prevent = false;

        for (const id in this.prevents) {
            const h = this.prevents[id];
            if (h.types && h.types.indexOf(type) >= 0) {
                prevent = prevent || !!h.handler(null);
            }
        }

        return prevent;
    },
};

// Asigna ExitPreventer al objeto window
window.ExitPreventer = ExitPreventer;

window.addEventListener('beforeunload', (e: BeforeUnloadEvent) => {
    const prevent = window.ExitPreventer.handlePreventSync("exit-page");

    if (prevent) {
        e.preventDefault();
        e.returnValue = '';
    }
});
